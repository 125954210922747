import React from 'react'
import { TopNavTitle } from './TopNavTitle'
import clsx from 'clsx'
import type { ImageType } from '../../../templates/Generic'

type TopNavProps = {
  data: {
    id: string
    title: string
    subtitle: string
    linkto: string
    icon?: ImageType
  }[]
  smallMargin?: boolean
  headerNoWrap?: boolean
  oneColumn?: boolean
}

export const TopNavMenu = ({
  data,
  smallMargin,
  headerNoWrap,
  oneColumn,
}: TopNavProps) => {
  return (
    <div
      className={clsx('grid w-80 gap-x-8 gap-y-6 lg:w-48 lg:gap-8 xl:w-80', {
        'mt-6 lg:mt-8': !smallMargin,
        'mt-4': smallMargin,
        'grid-cols-2 lg:grid-cols-1 xl:grid-cols-2': !oneColumn,
        'grid-cols-1': oneColumn,
      })}
    >
      {data.map(item => (
        <TopNavTitle
          key={item.id}
          title={item.title}
          content={item.subtitle}
          link={item.linkto}
          icon={item.icon}
          headerNoWrap={headerNoWrap}
        />
      ))}
    </div>
  )
}
