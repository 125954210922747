import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from '../../hooks'
import { TopNavTitle } from './TopNavTitle'
import { TopNavMenu } from './TopNavMenu'
import clsx from 'clsx'
import type { ImageType } from '../../../templates/Generic'

export const TopNavComplex = ({
  show,
  data,
  setShow,
}: {
  show: string
  data: {
    dropdownSection: {
      id: string
      title: string
      subtitle: string
      linkto: string
      dropdownLinks: {
        id: string
        linkto: string
        title: string
        subtitle: string
      }[]
      dropdownGroups: {
        icon: ImageType
        id: string
        linkto: string
        title: string
        subtitle: string
        dropdownLinks: {
          id: string
          linkto: string
          title: string
          subtitle: string
        }[]
      }[]
    }[]
  }
  setShow: React.Dispatch<React.SetStateAction<string>>
}) => {
  const wrapperRef = useRef(null)
  useOnClickOutside(wrapperRef, () => setShow(''))
  const [fadeIn, setFadeIn] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setFadeIn(true)
    }, 1)
  }, [])

  return data?.dropdownSection?.length > 0 ? (
    <div
      ref={wrapperRef}
      className={clsx(
        `z-50 mt-4 bg-white lg:left-0 lg:rounded-[2rem] lg:px-12 lg:py-8 lg:shadow-standard-bottom-only`,
        `transition-opacity duration-300`,
        {
          'opacity-0': !fadeIn,
          'opacity-1': fadeIn,
          'static lg:absolute': show,
          hidden: !show,
        },
      )}
    >
      <div className="flex flex-col lg:flex-row">
        {data.dropdownSection.map(s => (
          <div
            key={s.id}
            className={clsx(
              'mb-8 border-b border-b-gray-300 pb-8',
              'last:mb-0 last:border-b-0 last:pb-0',
              'last:mr-0 last:border-r-0 last:pr-0',
              'lg:border-r-solid lg:mr-8 lg:border-b-0 lg:border-r lg:border-r-gray-300 lg:pr-8',
            )}
          >
            <TopNavTitle
              title={s.title}
              content={s.subtitle}
              link={s.linkto || ''}
              isHeader
            />

            {s.dropdownGroups.length > 0 ? (
              <div className="mt-6 flex flex-col gap-8 lg:flex-row">
                {s.dropdownGroups.map(g => (
                  <div key={g.id}>
                    <TopNavTitle
                      title={g.title}
                      content={g.subtitle}
                      link={g.linkto || ''}
                      icon={g.icon}
                      isSubheader
                      hasBorder
                      noWrap
                    />

                    <TopNavMenu data={g.dropdownLinks} smallMargin />
                  </div>
                ))}
              </div>
            ) : null}

            {s.dropdownLinks.length > 0 && (
              <TopNavMenu
                data={s.dropdownLinks}
                oneColumn
                headerNoWrap={false}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  ) : null
}
