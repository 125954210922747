import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from '../../components'
import { breakpoints } from '../consts'
import {
  AnnouncementBanner,
  TopBarButtons,
  TopBarLogo,
  TopBarMenu,
  TopBarMobileMenu,
  TopBarMobileSubmenu,
} from './Components'

export const TopBar = () => {
  // Get button info
  const {
    datoCmsTopBar: { announcementBanner, topBarButtons, topBarMenu },
  } = useStaticQuery(graphql`
    {
      datoCmsTopBar {
        announcementBanner {
          announcement
          bannerColor
          linkto
        }
        topBarButtons {
          ... on DatoCmsButton {
            btnType
            linkTo
            label
            size
          }
          ... on DatoCmsSublink {
            title
            linkTo
          }
        }
        topBarMenu {
          ... on DatoCmsTopNavigationSection {
            id
            model {
              apiKey
            }
            title
            linkto
            hasDropdown
            dropdownTitle
            dropdownSubtitle
            dropdownLinks {
              id
              title
              subtitle
              linkto
            }
          }
          ... on DatoCmsTopNavigationSectionComplex {
            id
            model {
              apiKey
            }
            title
            linkto
            hasDropdown
            dropdownSection {
              id
              title
              subtitle
              linkto
              dropdownLinks {
                id
                icon {
                  filename
                  alt
                  url
                }
                title
                subtitle
                linkto
              }
              dropdownGroups {
                id
                title
                subtitle
                linkto
                icon {
                  filename
                  alt
                  url
                }
                dropdownLinks {
                  id
                  icon {
                    filename
                    alt
                    url
                  }
                  title
                  subtitle
                  linkto
                }
              }
            }
          }
        }
      }
    }
  `)
  // Mobile menu state control
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [submenu, setSubmenu] = useState('')
  // Determines whether the main mobile menu will slide in from the right
  const [animate, setAnimate] = useState(false)
  // Determine whether to show TopNav
  const [show, setShow] = useState('')

  const { matches: renderMobile } = useMediaQuery(breakpoints.down('lg'))

  useEffect(() => {
    if (showMobileMenu && renderMobile) {
      document.querySelector('body')?.classList.add('overflow-hidden')
    } else {
      document.querySelector('body')?.classList.remove('overflow-hidden')
    }
  }, [showMobileMenu, renderMobile])

  return (
    <>
      <div>
        {announcementBanner.length > 0 && (
          <AnnouncementBanner data={announcementBanner} />
        )}
        <div className="absolute w-screen px-4 pb-2 pt-12 text-gray-900 md:px-8">
          <div
            className={`relative grid w-full max-w-page grid-cols-1 md:mx-auto lg:grid-cols-[1fr_1fr]`}
          >
            {renderMobile ? (
              <>
                {showMobileMenu && (
                  <TopBarMobileSubmenu
                    menuItems={topBarMenu}
                    show={showMobileMenu}
                    setShow={setShowMobileMenu}
                    submenu={submenu}
                    setSubmenu={setSubmenu}
                    topBarButtons={topBarButtons}
                  />
                )}
                <TopBarMobileMenu
                  show={showMobileMenu}
                  setShow={setShowMobileMenu}
                />
              </>
            ) : (
              <>
                <div className="flex-cols flex w-max">
                  <TopBarLogo />
                  <TopBarMenu
                    menuItems={topBarMenu}
                    setShow={setShow}
                    show={show}
                  />
                </div>
                <TopBarButtons topBarButtons={topBarButtons} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
